<template>
  <div class="d-flex align-content-center justify-content-center">
    <div class="loader flex-shrink-1"></div>
    <span class="p ml-2 text-muted">{{ message }}</span>
  </div>
</template>

<script>
export default {
  name: "SpinnyMessage",
  components: {},

  props: {
    message: {
      type: String,
      default: "Please wait...",
    },
  },

  data() {
    return {};
  },

  computed: {},

  created() {},

  mounted() {},

  methods: {},
};
</script>

<style scoped>
.loader {
  position: relative;
  border: 6px solid #f3f3f3; /* Light grey */
  border-top: 6px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
  display: inline-block;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
