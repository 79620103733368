import Vue from "vue";
import Vuex from "vuex";

import auth from "./modules/auth";
import customer from "./modules/customer";
import common from "./modules/common";
import notifications from "./modules/notifications";
import service from "./modules/service"
import pump from "./modules/pump"
import admin from "./modules/admin"

import { createLogger } from "vuex";

// const debug = process.env.NODE_ENV !== "production";
// const plugins = debug ? [createLogger({})] : [];

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth: auth,
    customer: customer,
    common: common,
    notifications: notifications,
    service: service,
    pump: pump,
    admin: admin,
  },
  plugins: [createLogger()],
});
