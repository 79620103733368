<template>
  <div class="row title-row my-2">
    <div class="col-lg-12 mx-auto">
      <div class="title-text">
        {{ title }}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "MPageTitle",
    components: {},

    props: {
      title: {
        type: String,
        required: true,
        default: "",
      },
    },

    data() {
      return {};
    },

    computed: {},

    created() {},

    mounted() {},

    methods: {},
  };
</script>

<style scoped>
  .title-row {
    background-color: #8f5187;
    padding: 5px;
    border-radius: 5px;
  }

  .title-text {
    font-size: 1.4rem;
    color: white;
  }
</style>
