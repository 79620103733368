<template>
  <div>Redirect Page</div>
</template>

<script>
export default {
  name: 'AuthRedirect',
  async mounted() {
    console.log('AuthRedirect mounted');
    
    // Set a flag to indicate that we are in the process of logging in
    // sessionStorage.setItem('loginInProgress', true);
    // Handle the redirect response from Azure
    let redirectResponse = await this.$store.dispatch('auth/handleRedirect');
    // Wait 5 seconds
    // await new Promise(resolve => setTimeout(resolve, 5000));
    // Clear the flag
    // sessionStorage.removeItem('loginInProgress');
    // Store the login response
    sessionStorage.setItem('loginResponse', JSON.stringify(redirectResponse));
    // Set the authentication flag
    // this.$store.dispatch("auth/setAuthentication", true);
    // Wait 5 seconds
    // await new Promise(resolve => setTimeout(resolve, 5000));
    // Redirect to the home page
    // this.$router.push({ name: 'Home' });
  },
}
</script>