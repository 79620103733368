const state = {
  new_request_yn: null,
  service_request_id: null,
  shipment_id: null,
  receiving_id: null,
}

const getters = {
  newRequestYN: (state) => state.new_request_yn,
  serviceRequestId: (state) => state.service_request_id,
  shipmentId: (state) => state.shipment_id,
  receivingId: (state) => state.receiving_id,
}

const actions = {
  setNewRequestFlag({ commit }, payload) {
    commit("SET_NEW_REQUEST_FLAG", payload)
  },
  setServiceRequestId({ commit }, payload) {
    commit("SET_SERVICE_REQUEST_ID", payload)
  },
  setShipmentId({ commit }, payload) {
    commit("SET_SHIPMENT_ID", payload)
  },
  setReceivingId({ commit }, payload) {
    commit("SET_RECEIVING_ID", payload)
  }
}

const mutations = {
  SET_NEW_REQUEST_FLAG(state, payload) {
    state.new_request_yn = payload
  },
  SET_SERVICE_REQUEST_ID(state, payload) {
    state.service_request_id = payload
  },
  SET_SHIPMENT_ID(state, payload) {
    state.shipment_id = payload
  },
  SET_RECEIVING_ID(state, payload) {
    state.receiving_id = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
