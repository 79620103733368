<template>
  <!-- <div class="p-1"> -->
  <div class="d-flex align-items-center my-2 hover-notification-item">
    <div class="flex-shrink-1 mr-2">
      <i
        class="fas mini notification-item icon-pointer"
        :class="item.message_read_yn ? 'fa-envelope-open' : 'fa-envelope-dot'"
        @click.prevent="markItemAsRead()"></i>
    </div>
    <div class="w-100">
      <div class="text-sm">{{ item.message }}</div>
      <div class="mini text-muted">
        {{ new Date(item.created_at).toLocaleString() }}
      </div>
      <!-- <span class="float-right text-muted mini">
        <span
          class="i fas fa-trash ml-1 mini text-danger icon-pointer"
          @click="deleteItem()"
        />
      </span> -->
    </div>
    <div class="flex-shrink-1">
      <div v-if="!showConfirmDelete">
        <i
          class="i fas fa-trash ml-1 mini text-danger icon-pointer"
          @click="
            () => {
              showConfirmDelete = true
            }
          "></i>
      </div>
      <div v-if="showConfirmDelete">
        <b-badge
          pill
          variant="success"
          class="d-inline icon-pointer mr-2"
          @click="deleteItem()"
          >Delete</b-badge
        >
        <b-badge
          pill
          variant="danger"
          class="d-inline icon-pointer"
          @click="
            () => {
              showConfirmDelete = false
            }
          "
          >Cancel</b-badge
        >
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
  export default {
    name: "MNotificationItem",
    components: {},

    props: {
      item: {
        type: Object,
        required: true,
        default: () => {},
      },
    },

    emits: ["read-notification-item", "delete-item"],

    data() {
      return {
        showConfirmDelete: false,
      }
    },

    computed: {},

    created() {},

    mounted() {},

    methods: {
      markItemAsRead() {
        this.$emit("read-notification-item", this.item)
      },
      deleteItem() {
        this.$emit("delete-item", this.item)
      },
      confirmDelete() {
        this.showConfirmDelete = true
      },
    },
  }
</script>

<style scoped>
  .notification-item:hover {
    color: aqua;
  }

  .mini {
    font-size: 0.7rem;
  }

  .badge {
    font-size: 50%;
  }
</style>
