/*!

=========================================================
* BootstrapVue Argon Dashboard PRO - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/bootstrap-vue-argon-dashboard-pro
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

=========================================================
SheetJS Community Edition -- https://sheetjs.com/
=========================================================

Copyright (C) 2012-present   SheetJS LLC

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

      http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.

*/
import Vue from "vue";
import DashboardPlugin from "./plugins/dashboard-plugin";
import App from "./App.vue";

import Emitter from "tiny-emitter";
import store from "@/store";

// Custom components
import Colxx from "./components/Colxx.vue";
// Input components
import MTextInput from "./components/input/MTextInput.vue";
import MTextArea from "./components/input/MTextArea.vue";
import MTrackingInfo from "./components/input/MTrackingInfo.vue";
import MSpinnyMessage from "@/components/loader/MSpinnyMessage.vue";
import MPageTitle from "@/components/MPageTitle.vue";

// custom colors
const root = document.documentElement;
root.style.setProperty("--blue", "#5e72e4");
root.style.setProperty("--indigo", "#5603ad");
root.style.setProperty("--purple", "#8965e0");
root.style.setProperty("--pink", "#f3a4b5");
root.style.setProperty("--red", "#f5365c");
root.style.setProperty("--orange", "#fb6340");
root.style.setProperty("--yellow", "#ffd600");
root.style.setProperty("--green", "#ACCD77");
root.style.setProperty("--teal", "#11cdef");
root.style.setProperty("--cyan", "#2bffc6");
root.style.setProperty("--default", "#008ab0");
root.style.setProperty("--primary", "#172b4d");
root.style.setProperty("--secondary", "#1626b3");
root.style.setProperty("--success", "#11cdef");
root.style.setProperty("--info", "#11cdef");
root.style.setProperty("--warning", "#fb6340");
root.style.setProperty("--danger", "#f5365c");
root.style.setProperty("--light", "#adb5bd");
root.style.setProperty("--dark", "#212529");
root.style.setProperty("--white", "#fff");
root.style.setProperty("--gray100", "#f6f9fc");
root.style.setProperty("--gray200", "#e9ecef");
root.style.setProperty("--gray300", "#dee2e6");
root.style.setProperty("--gray400", "#ced4da");
root.style.setProperty("--gray500", "#adb5bd");
root.style.setProperty("--gray600", "#8898aa");
root.style.setProperty("--gray700", "#525f7f");
root.style.setProperty("--gray800", "#32325d");
root.style.setProperty("--gray900", "#212529");
root.style.setProperty("--black", "#000");
root.style.setProperty("--blue-lighter-20", "#7e8ee9");
root.style.setProperty("--indigo-lighter-20", "#7735bd");
root.style.setProperty("--purple-lighter-20", "#a083e6");
root.style.setProperty("--pink-lighter-20", "#f5b6c3");
root.style.setProperty("--red-lighter-20", "#f75e7c");
root.style.setProperty("--orange-lighter-20", "#fb8266");
root.style.setProperty("--yellow-lighter-20", "#ffde33");
root.style.setProperty("--green-lighter-20", "#bcd792");
root.style.setProperty("--teal-lighter-20", "#40d7f2");
root.style.setProperty("--cyan-lighter-20", "#55ffd1");
root.style.setProperty("--default-lighter-20", "#33a1bf");
root.style.setProperty("--primary-lighter-20", "#455570");
root.style.setProperty("--secondary-lighter-20", "#4451c2");
root.style.setProperty("--success-lighter-20", "#40d7f2");
root.style.setProperty("--info-lighter-20", "#40d7f2");
root.style.setProperty("--warning-lighter-20", "#fb8266");
root.style.setProperty("--danger-lighter-20", "#f75e7c");
root.style.setProperty("--blue-darker-20", "#4b5bb6");
root.style.setProperty("--indigo-darker-20", "#44028a");
root.style.setProperty("--purple-darker-20", "#6d50b3");
root.style.setProperty("--pink-darker-20", "#c28390");
root.style.setProperty("--red-darker-20", "#c42b49");
root.style.setProperty("--orange-darker-20", "#c84f33");
root.style.setProperty("--yellow-darker-20", "#ccab00");
root.style.setProperty("--green-darker-20", "#89a45f");
root.style.setProperty("--teal-darker-20", "#0da4bf");
root.style.setProperty("--cyan-darker-20", "#22cc9e");
root.style.setProperty("--default-darker-20", "#006e8c");
root.style.setProperty("--primary-darker-20", "#12223d");


// router setup
import router from "./routes/router";
// plugin setup
Vue.use(DashboardPlugin);

Vue.component("b-colxx", Colxx);
Vue.component("m-text-input", MTextInput);
Vue.component("m-text-area", MTextArea);
Vue.component("m-tracking-info", MTrackingInfo);
Vue.component("m-spinny-message", MSpinnyMessage);
Vue.component("m-page-title", MPageTitle);

Vue.prototype.$msalInstance = {};
Vue.prototype.$emitter = new Emitter();

// Vue.prototype.$globalData = Vue.observable({ token: localStorage.getItem("MCP_TOKEN_STORAGE_KEY") });

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
