<template>
  <base-nav
    container-classes="container-fluid"
    class="navbar-top border-bottom navbar-expand"
    :class="{ 'bg-success navbar-dark': type === 'default' }">
    <!-- Search form -->

    <!-- <b-form class="navbar-search form-inline mr-sm-3"
          :class="{'navbar-search-light': type === 'default', 'navbar-search-dark': type === 'light'}"
          id="navbar-search-main">
      <b-form-group class="mb-0">
        <b-input-group class="input-group-alternative input-group-merge">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fas fa-search"></i></span>
          </div>
          <b-form-input placeholder="Search" type="text"> </b-form-input>
        </b-input-group>
      </b-form-group>
      <button type="button" class="close" data-action="search-close" data-target="#navbar-search-main" aria-label="Close">
        <span aria-hidden="true">×</span>
      </button>
    </b-form> -->

    <!-- Navbar links -->
    <b-navbar-nav class="align-items-center ml-md-auto">
      <!-- This item dont have <b-nav-item> because they add class 'nav-link' which is not needed here -->
      <li class="nav-item d-xl-none">
        <!-- Sidenav toggler -->
        <div
          class="pr-3 sidenav-toggler"
          :class="{
            active: $sidebar.showSidebar,
            'sidenav-toggler-dark': type === 'default',
            'sidenav-toggler-light': type === 'light',
          }"
          @click="toggleSidebar">
          <div class="sidenav-toggler-inner">
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
          </div>
        </div>
      </li>
      <!-- This item dont have <b-nav-item> because item have data-action/data-target on tag <a>, wich we cant add -->
      <!-- <li class="nav-item d-sm-none">
        <a class="nav-link" href="#" data-action="search-show" data-target="#navbar-search-main">
          <i class="ni ni-zoom-split-in"></i>
        </a>
      </li> -->
    </b-navbar-nav>
    <b-navbar-nav
      v-if="envName != 'production'"
      class="align-items-center ml-0">
      <b-badge
        variant="danger"
        class="mr-4">
        {{ envName }} Environment
      </b-badge>
    </b-navbar-nav>
    <b-navbar-nav class="align-items-center ml-auto">
      <i
        class="fas fa-bell icon-pointer text-white mr-4"
        @click="toggleNotificationModal()">
        <span class="badge notification-badge">{{ notificationUnreadCount }}</span>
      </i>
    </b-navbar-nav>
    <b-navbar-nav class="align-items-center ml-auto ml-md-0">
      <base-dropdown
        menu-on-right
        class="nav-item"
        tag="li"
        title-tag="a"
        title-classes="nav-link pr-0"
        menu-classes="dropdown-menu-sm dropdown-menu-right py-0 overflow-hidden">
        <a
          href="#"
          class="nav-link pr-0"
          @click.prevent
          slot="title-container">
          <span class="mb-0 text-sm font-weight-bold">{{ user.full_name }}</span>
        </a>

        <template>
          <b-dropdown-header class="noti-title">
            <h6 class="text-overflow m-0">Welcome!</h6>
          </b-dropdown-header>
          <b-dropdown-item @click.prevent="goMyProfile()">
            <i class="fas fa-user"></i>
            <span>My profile</span>
          </b-dropdown-item>
          <div class="dropdown-divider"></div>
          <b-dropdown-item @click.prevent="signOut()">
            <i class="fas fa-door-open"></i>
            <span>Logout</span>
          </b-dropdown-item>
        </template>
      </base-dropdown>
    </b-navbar-nav>
  </base-nav>
</template>

<script>
  import { BaseNav } from "@/components"
  import { mapGetters } from "vuex"
  import { envName } from "@/constants/common"

  export default {
    name: "NavBar",
    components: {
      BaseNav,
      // MNotificationItem,
    },
    props: {
      type: {
        type: String,
        default: "default", // default|light
        description: "Look of the dashboard navbar. Default (Green) or light (gray)",
      },
    },
    computed: {
      routeName() {
        const { name } = this.$route
        return this.capitalizeFirstLetter(name)
      },

      notificationCount() {
        let list = this.notificationList
        let unread_list = list.filter((x) => x.message_read_yn === false)
        return unread_list.length
      },

      ...mapGetters("auth", ["user", "isAuthenticated"]),
      ...mapGetters("notifications", ["notificationUnreadCount"]),
    },
    data() {
      return {
        activeNotifications: false,
        showMenu: false,
        searchModalVisible: false,
        searchQuery: "",

        notificationList: [],
        getNotifications: null,
        envName: envName,
        // getToken: null,
      }
    },

    created() {},

    beforeDestroy() {
      clearInterval(this.getNotifications)
      // clearInterval(this.getToken);
    },

    mounted() {
      // https://stackoverflow.com/questions/49849376/vue-js-triggering-a-method-function-every-x-seconds
      if (envName === "production") {
        this.getNotifications = setInterval(() => {
          this.$store.dispatch("notifications/getNotifications")
        }, 60000)
      } else {
        this.getNotifications = setInterval(() => {
          this.$store.dispatch("notifications/getNotifications")
        }, 600000)
      }
    },

    methods: {
      readNotificationItem(item) {
        console.log("Emit", item)
        let getNotificationItemIndex = this.notificationList.findIndex(
          (x) => x.notification_id === item.notification_id
        )
        this.notificationList[getNotificationItemIndex].message_read_yn =
          !this.notificationList[getNotificationItemIndex].message_read_yn
        console.log(this.notificationList[getNotificationItemIndex].message_read_yn)
      },

      capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      },

      toggleNotificationModal() {
        this.$store.dispatch("common/toggleNotificationModal")
      },

      // toggleNotificationDropDown() {
      //   this.activeNotifications = !this.activeNotifications;
      // },
      // closeDropDown() {
      //   this.activeNotifications = false;
      // },

      toggleSidebar() {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
      },

      hideSidebar() {
        this.$sidebar.displaySidebar(false)
      },

      goMyProfile() {
        this.$router.push({ name: "MyProfile" })
      },

      async signOut() {
        await this.$store
          .dispatch("auth/logout", this.user.azure_client_id)
          .then(() => {
            this.$router.push({ name: "Login" })
          })
          .catch((error) => {
            console.error(error)
          })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .notification-badge {
    position: relative;
    right: 10px;
    top: -15px;
    background-color: purple;
    border-radius: 40%;
    font-size: 0.5rem;
  }
</style>
