var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-content"},[(_vm.isAuthenticated)?_c('notifications'):_vm._e(),(_vm.isAuthenticated)?_c('side-bar',[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Home',
          icon: 'fas fa-home text-green',
          path: '/home',
        }}}),(
          _vm.permissions.includes('CanViewAllCustomers') ||
          _vm.permissions.includes('CanViewOwnCustomers') ||
          _vm.permissions.includes('CanViewContact') ||
          _vm.permissions.includes('CanViewSalesCall') ||
          _vm.permissions.includes('CanViewSalesInvoices')
        )?_c('sidebar-item',{attrs:{"link":{
          name: 'Customers',
          icon: 'fas fa-buildings text-info',
          path: '/customers',
        }}},[(_vm.permissions.includes('CanViewAllCustomers') || _vm.permissions.includes('CanViewOwnCustomers'))?_c('sidebar-item',{attrs:{"link":{ name: 'Companies', path: '/customers/companies' }}}):_vm._e(),(_vm.permissions.includes('CanViewContact'))?_c('sidebar-item',{attrs:{"link":{ name: 'Contacts', path: '/customers/contacts' }}}):_vm._e(),(_vm.permissions.includes('CanViewSalesCall'))?_c('sidebar-item',{attrs:{"link":{ name: 'Sales Calls', path: '/customers/sales-calls' }}}):_vm._e(),(_vm.permissions.includes('CanViewSalesInvoices'))?_c('sidebar-item',{attrs:{"link":{
            name: 'Sales Invoices',
            path: '/invoices',
          }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{ name: 'Customer Types', path: '/admin/customer-types' }}})],1):_vm._e(),(_vm.permissions.includes('CanViewProducts'))?_c('sidebar-item',{attrs:{"link":{
          name: 'Products',
          icon: 'fas fa-warehouse-full text-danger-tint-5',
          path: '/products',
        }}}):_vm._e(),(_vm.permissions.includes('CanViewLogistics'))?_c('sidebar-item',{attrs:{"link":{
          name: 'Logistics',
          icon: 'fas fa-truck-fast text-info-tint-5',
          path: '/logistics',
        }}}):_vm._e(),(
          _vm.permissions.includes('CanViewServiceRequests') ||
          _vm.permissions.includes('CanViewShippingRecords') ||
          _vm.permissions.includes('CanViewReceivingRecords')
        )?_c('sidebar-item',{attrs:{"link":{
          name: 'Service',
          icon: 'fas fa-hammer text-warning-tint-4',
          path: '/service',
        }}},[(_vm.permissions.includes('CanViewServiceRequests'))?_c('sidebar-item',{attrs:{"link":{ name: 'Service Requests', path: '/service/request-home' }}}):_vm._e(),(_vm.permissions.includes('CanViewShippingRecords'))?_c('sidebar-item',{attrs:{"link":{ name: 'Shipping', path: '/service/shipping' }}}):_vm._e(),(_vm.permissions.includes('CanViewReceivingRecords'))?_c('sidebar-item',{attrs:{"link":{ name: 'Receiving', path: '/service/receiving' }}}):_vm._e()],1):_vm._e(),(_vm.permissions.includes('CanViewDevices') || _vm.permissions.includes('CanViewDeviceLocations'))?_c('sidebar-item',{attrs:{"link":{
          name: 'Equipment',
          icon: 'fas fa-pump text-orange',
          path: '/pumps',
        }}},[(_vm.permissions.includes('CanViewDevices'))?_c('sidebar-item',{attrs:{"link":{ name: 'Device', path: '/pumps/home' }}}):_vm._e(),(_vm.permissions.includes('CanViewDeviceLocations'))?_c('sidebar-item',{attrs:{"link":{ name: 'Device Location', path: '/pumps/depots' }}}):_vm._e()],1):_vm._e(),(_vm.permissions.includes('CanViewSalesCommissions'))?_c('sidebar-item',{attrs:{"link":{
          name: 'Targets & Commissions',
          icon: 'fas fa-dollar text-green',
          path: '/commissions',
        }}},[(_vm.roles.includes('ADMIN'))?_c('sidebar-item',{attrs:{"link":{ name: 'Sales Targets', path: '/commissions/targets' }}}):_vm._e(),(_vm.permissions.includes('CanViewSalesCommissions'))?_c('sidebar-item',{attrs:{"link":{ name: 'Commission Reports', path: '/commissions/reports' }}}):_vm._e()],1):_vm._e(),_c('sidebar-item',{attrs:{"link":{
          name: 'Reports',
          icon: 'fas fa-file text-indigo',
          path: '/reports',
        }}},[(_vm.roles.includes('SLS_REP') || _vm.roles.includes('ADMIN') || _vm.roles.includes('SLS_MGR'))?_c('sidebar-item',{attrs:{"link":{ name: 'Customer Weekly Sales', path: '/reports/customer-sales-history' }}}):_vm._e(),(_vm.roles.includes('SLS_REP') || _vm.roles.includes('ADMIN') || _vm.roles.includes('SLS_MGR'))?_c('sidebar-item',{attrs:{"link":{ name: 'Customer Sales Detail', path: '/reports/customer-sales-detail' }}}):_vm._e(),(_vm.roles.includes('SLS_REP') || _vm.roles.includes('ADMIN') || _vm.roles.includes('SLS_MGR'))?_c('sidebar-item',{attrs:{"link":{ name: 'Supplier Sales', path: '/reports/supplier-sales-history' }}}):_vm._e(),(_vm.roles.includes('SLS_REP') || _vm.roles.includes('ADMIN') || _vm.roles.includes('SLS_MGR'))?_c('sidebar-item',{attrs:{"link":{ name: 'Customer by Territory', path: '/reports/customer-territory-list' }}}):_vm._e(),(
            _vm.roles.includes('ADMIN') ||
            _vm.roles.includes('CUST_SRV') ||
            _vm.roles.includes('OFF_MGR') ||
            _vm.roles.includes('WAREHOUSE')
          )?_c('sidebar-item',{attrs:{"link":{ name: 'Service Request History', path: '/reports/service-request-history' }}}):_vm._e(),(_vm.roles.includes('ADMIN') || _vm.roles.includes('CUST_SRV') || _vm.roles.includes('OFF_MGR'))?_c('sidebar-item',{attrs:{"link":{ name: 'Rental Pump Invoicing', path: '/reports/rental-pump-report' }}}):_vm._e(),(_vm.roles.includes('SLS_REP') || _vm.roles.includes('ADMIN') || _vm.roles.includes('SLS_MGR'))?_c('sidebar-item',{attrs:{"link":{ name: 'Sales Performance', path: '/reports/sales-performance-report' }}}):_vm._e(),(
            _vm.roles.includes('ADMIN') ||
            _vm.roles.includes('CUST_SRV') ||
            _vm.roles.includes('OFF_MGR') ||
            _vm.roles.includes('WAREHOUSE')
          )?_c('sidebar-item',{attrs:{"link":{ name: 'Depot Inventory Report', path: '/reports/depot-inventory-report' }}}):_vm._e()],1),(_vm.userIsAdmin)?_c('sidebar-item',{attrs:{"link":{
          name: 'Admin',
          icon: 'fas fa-gears text-grey',
          path: '/admin',
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Users', path: '/admin/users' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'User Roles', path: '/admin/roles' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Territories', path: '/admin/territories' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Suppliers', path: '/admin/suppliers' }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Sales Call Types',
            path: '/admin/sales-call-types',
          }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Price Lists', path: '/admin/price-lists' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Pump Types', path: '/admin/pump-types' }}})],1):_vm._e(),(_vm.userIsAdmin)?_c('sidebar-item',{attrs:{"link":{
          name: 'Release Notes',
          icon: 'fas fa-book text-light',
          path: '/release-notes',
        }}}):_vm._e()],1)],2):_vm._e(),_c('div',{staticClass:"main-content"},[(_vm.isAuthenticated)?_c('navbar',{attrs:{"type":_vm.$route.meta.navbarType}}):_vm._e(),(_vm.showLoader)?_c('div',[_c('m-loader')],1):_vm._e(),_c('div',{on:{"click":function($event){return _vm.$sidebar.displaySidebar(false)}}},[_c('div',{staticClass:"container-fluid",attrs:{"id":"root"}},[_c('router-view')],1)]),_c('b-modal',{staticClass:"notification-modal",attrs:{"id":"notification-modal","size":"lg","scrollable":"","header-bg-variant":"warning","centered":"","hide-footer":"","no-close-on-esc":"","no-close-on-backdrop":"","hide-header-close":""},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('b-row',{staticClass:"align-items-center text-white"},[_c('b-colxx',{attrs:{"md":"10","sm":"10"}},[_c('span',{staticClass:"h3 text-white"},[_vm._v("Notifications")])]),_c('b-colxx',{staticClass:"pull-right",attrs:{"md":"2","sm":"2"}},[_c('span',{staticClass:"i fas fa-circle-xmark text-white icon-pointer",on:{"click":function($event){return _vm.toggleNotificationModal()}}})])],1)]},proxy:true}]),model:{value:(_vm.notificationModalState),callback:function ($$v) {_vm.notificationModalState=$$v},expression:"notificationModalState"}},[[_c('div',{staticClass:"p-0"},_vm._l((_vm.notificationList),function(notify_item,index){return _c('div',{key:index},[_c('m-notification-item',{attrs:{"item":notify_item},on:{"read-notification-item":function($event){return _vm.readNotificationItem($event)},"delete-item":function($event){return _vm.deleteNotificationItem($event)}}})],1)}),0)]],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }