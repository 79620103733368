const state = {
  customer: {},
  customer_id: null,
  is_prospect: false,
  
};

const getters = {
  customer: state => state.customer,
  customerId: state => state.customer_id,
  isProspect: state => state.is_prospect,
};

const actions = {
  setCustomerId({ commit }, payload) {
    commit('SET_CUSTOMER_ID', payload);
  },

  setProspectFlag ({ commit }, payload ) {
    commit('SET_PROSPECT_FLAG', payload)
  }
};

const mutations = {
  SET_CUSTOMER_ID(state, payload){
    state.customer_id = payload
  },
  SET_PROSPECT_FLAG(state, payload) {
    state.is_prospect = payload
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
