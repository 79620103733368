import django_session from "@/api/django_session"

const state = {
  pump_id: null,
  pump_obj: {},
  edit_pump_from_depot_yn: false,
  pump_types: [],
}

const getters = {
  pumpId: (state) => state.pump_id,
  pumpObj: (state) => state.pump_obj,
  editPumpFromDepotYN: (state) => state.edit_pump_from_depot_yn,
  pumpTypes: (state) => state.pump_types,
}

const actions = {
  setPumpId({ commit }, payload) {
    commit("SET_PUMP_ID", payload)
  },

  setEditPumpFromDepotFlat({ commit }, payload) {
    commit("SET_EDIT_PUMP_FROM_DEPOT_YN", payload)
  },

  setPumpObj({ commit }, payload) {
    commit("SET_PUMP_OBJ", payload)
  },

  async getPumpTypes({ commit }, payload) {
    await django_session
      .get("erp/pump/type/list/", {
        params: {
          page_size: 0,
        },
      })
      .then((response) => {
        commit("SET_PUMP_TYPE_LIST", response.data)
      })
      .catch((error) => {
        console.log(error.response)
      })
  },

  addPumpType({ commit }, payload) {
    commit("ADD_PUMP_TYPE", payload)
  },

  editPumpType({ commit }, payload) {
    commit("EDIT_PUMP_TYPE", payload)
  }
}

const mutations = {
  SET_PUMP_ID(state, payload) {
    state.pump_id = payload
  },

  SET_EDIT_PUMP_FROM_DEPOT_YN(state, payload) {
    state.edit_pump_from_depot_yn = payload
  },

  SET_PUMP_OBJ(state, payload) {
    state.pump_obj = payload
  },

  SET_PUMP_TYPE_LIST(state, payload) {
    state.pump_types = payload
  },

  ADD_PUMP_TYPE(state, payload) {
    state.pump_types.push(payload)
  },

  EDIT_PUMP_TYPE(state, payload) {
    state.pump_types[payload.index] = payload.data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
