const state = {
  edit_user_yn: null,
}

const getters = {
  editUserYN: (state) => state.edit_user_yn,
}

const actions = {
  setEditUserYN({ commit }, payload) {
    commit("SET_EDIT_USER_YN", payload)
  },
}

const mutations = {
  SET_EDIT_USER_YN(state, payload) {
    state.edit_user_yn = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
