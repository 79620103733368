<template>
  <div class="row my-1">
    <div class="col-md-3 col-sm-12">
      <m-text-input
        id="created-by-name"
        label="Created By"
        :value="createdByName"
        :alternative="true"
        :group="false"
        :disabled="true"
        name="Created By"
      />
    </div>

    <div class="col-md-3 col-sm-12">
      <m-text-input
        id="created-at"
        label="Created At"
        :value="formatCreatedDate"
        :alternative="true"
        :group="false"
        :disabled="true"
        name="Created At"
      />
    </div>

    <div class="col-md-3 col-sm-12">
      <m-text-input
        id="modified-by-name"
        label="Modified By"
        :value="modifiedByName"
        :alternative="true"
        :group="false"
        :disabled="true"
        name="Modified By"
      />
    </div>

    <div class="col-md-3 col-sm-12">
      <m-text-input
        id="modified-at"
        label="Modified At"
        :value="formatModifiedDate"
        :alternative="true"
        :group="false"
        :disabled="true"
        name="Modified At"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "MTrackingInfo",
  components: {},

  props: {
    createdByName: {
      type: String,
      required: true,
      default: "",
    },
    createdAt: {
      type: String,
      required: true,
      default: "",
    },
    modifiedByName: {
      type: String,
      required: true,
      default: "",
    },
    modifiedAt: {
      type: String,
      required: true,
      default: "",
    },
  },

  data() {
    return {
    };
  },

  computed: {
    formatCreatedDate() {
      if (this.createdAt === null || this.createdAt === "" || this.createdAt === undefined) {
        return "";
      } else {
        return new Date(this.createdAt).toLocaleString();
      }
    },
    formatModifiedDate() {
      if (this.modifiedAt === null || this.modifiedAt === "" || this.modifiedAt === undefined) {
        return "";
      } else {
        return new Date(this.modifiedAt).toLocaleString();
      }
    },
  },

  created() {},

  mounted() {},

  methods: {},
};
</script>
