var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-provider',_vm._b({attrs:{"rules":_vm.rules,"name":_vm.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('b-form-group',{staticClass:"mt-0",attrs:{"id":_vm.id}},[_vm._t("label",function(){return [(_vm.label)?_c('label',{staticClass:"field-label-form-group pt-0 mt-0",class:_vm.labelClasses},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e()]}),_c('div',{class:[
        { 'input-group': _vm.hasIcon },
        { focused: _vm.focused },
        { 'input-group-alternative': _vm.alternative },
        { 'has-label': _vm.label || _vm.$slots.label },
        _vm.inputGroupClasses ]},[_vm._t("default",function(){return [_c('textarea',_vm._g(_vm._b({staticClass:"form-control",class:[
            { 'is-valid': valid && validated && _vm.successMessage },
            { 'is-invalid': invalid && validated },
            _vm.inputClasses ],attrs:{"valid":valid,"rows":_vm.rows,"readonly":_vm.readonly,"disabled":_vm.disabled},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.$emit('update-field', $event.target.value)},"focus":function($event){_vm.isInputActive = true},"blur":function($event){_vm.isInputActive = false}}},'textarea',_vm.$attrs,false),_vm.listeners))]},null,_vm.slotData),_vm._t("infoBlock")],2),_vm._t("success",function(){return [(valid && validated && _vm.successMessage)?_c('div',{staticClass:"valid-feedback"},[_vm._v(" "+_vm._s(_vm.successMessage)+" ")]):_vm._e()]}),_c('b-row',{staticClass:"d-flex",attrs:{"align-h":"between"}},[_c('b-col',[_vm._t("error",function(){return [(errors[0])?_c('div',{staticClass:"invalid-feedback text-xs",staticStyle:{"display":"inline-block"}},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]})],2),_c('b-col',[(_vm.rules.max)?_c('span',{staticClass:"text-muted text-xs float-right"},[_vm._v(" "+_vm._s(_vm.charCount.value_length)+"/"+_vm._s(_vm.charCount.max_length)+" ")]):_vm._e()])],1)],2)]}}],null,true)},'validation-provider',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }