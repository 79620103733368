import django_session from "@/api/django_session";

const state = {
  notification_list: [],
};

const getters = {
  notificationList: (state) => state.notification_list,
  notificationCount: (state) => state.notification_list.length,
  notificationUnreadCount: (state) =>
    state.notification_list.filter((x) => x.message_read_yn === false).length,
};

const actions = {
  async getNotifications({ rootState, commit }, payload) {
    let user_id = rootState.auth.user.user_id;
    django_session
      .get("erp/user/notification-list/", {
        params: {
          user_id: user_id,
          page_size: 0
        },
      })
      .then((response) => {
        // console.log("vuex notifications", response.data.results);
        payload = response.data;
        commit("SET_NOTIFICATION_LIST", payload);
      });
  },

  async updateNotification({ commit }, payload) {
    await django_session
      .patch("erp/user/notification-list/" + payload.notification_id + "/", {
        message_read_yn: payload.message_read_yn,
      })
      .then((response) => {
        commit("UPDATE_NOTIFICATION_ITEM", response.data);
      });
  },

  async deleteNotification({ commit }, payload) {
    await django_session
      .delete("erp/user/notification-list/" + payload.notification_id + "/")
      .then(() => {
        commit("DELETE_NOTIFICATION_ITEM", payload);
      });
  },
};

const mutations = {
  SET_NOTIFICATION_LIST(state, payload) {
    state.notification_list = payload;
  },

  UPDATE_NOTIFICATION_ITEM(state, payload) {
    console.log(payload);
    let itemIndex = state.notification_list.findIndex(
      (x) => x.notification_id === payload.notification_id
    );
    state.notification_list[itemIndex].message_read_yn =
      payload.message_read_yn;
  },

  DELETE_NOTIFICATION_ITEM(state, payload) {
    let itemIndex = state.notification_list.findIndex(
      (x) => x.notification_id === payload.notification_id
    );
    state.notification_list.splice(itemIndex, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
