import django_session from "@/api/django_session";

const state = {
  current_page: 1,
  show_loader: false,
  previous_view: {},
  entity_list: [],
  notification_modal: false,
  service_request_type_codes: [],
  request_status_codes: [],
  // service_request_reason_codes: [],
};

const getters = {
  currentPage: (state) => state.current_page,
  showLoader: (state) => state.show_loader,
  previousView: (state) => state.previous_view,
  entityList: (state) => state.entity_list,
  notificationModalState: (state) => state.notification_modal,
  serviceRequestTypeCodes: state => state.service_request_type_codes,
  requestStatusCodes: state => state.request_status_codes,
  // requestReasonCodes: state => state.service_request_reason_codes,
};

const actions = {
  setCurrentPage({ commit }, payload) {
    commit("SET_CURRENT_PAGE", payload);
  },
  showLoader({ commit }, payload) {
    commit("SHOW_LOADER", payload);
  },
  setPreviousView({ commit }, payload) {
    commit("SET_PREVIOUS_VIEW", payload);
  },
  getPreviousView(state) {
    return state.previous_view;
  },

  getEntityList({ commit }) {
    django_session
      .get("erp/common/entity-list/", {
        params: {
          page_size: 0,
        },
      })
      .then((response) => {
        let payload = response.data;
        commit("SET_ENTITY_LIST", payload)
      });
  },

  toggleNotificationModal({ commit }) {
    commit("SET_NOTIFICATION_MODAL_STATE");
  },
};

const mutations = {
  SET_CURRENT_PAGE(state, payload) {
    state.current_page = payload;
  },
  SHOW_LOADER(state, payload) {
    state.show_loader = payload;
  },
  SET_PREVIOUS_VIEW(state, payload) {
    state.previous_view = payload;
  },

  SET_ENTITY_LIST(state, payload) {
    state.entity_list = payload;
    state.service_request_type_codes = payload.find(
      (entry) => entry.entity_type_class_name === "servicerequesttypecode"
    ).type_codes
    // sort service_request_type_codes by name
    state.service_request_type_codes.sort((a, b) => (a.type_label > b.type_label) ? 1 : -1)

    state.request_status_codes = payload.find((entry) => entry.entity_type_class_name === "requeststatus").type_codes

    // state.service_request_reason_codes = payload.find(
    //   (entry) => entry.entity_type_class_name === "servicerequestreasoncode"
    // ).type_codes
  },

  SET_NOTIFICATION_MODAL_STATE() {
    state.notification_modal = !state.notification_modal;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
