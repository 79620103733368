<template>
  <b-col
    :class="classes"
    v-bind="options"
  >
    <slot />
  </b-col>
</template>
<script>
export default {
  props: [
    // eslint-disable-next-line vue/require-prop-types
    "xxs",
    // eslint-disable-next-line vue/require-prop-types
    "xs",
    // eslint-disable-next-line vue/require-prop-types
    "sm",
    // eslint-disable-next-line vue/require-prop-types
    "md",
    // eslint-disable-next-line vue/require-prop-types
    "lg",
    // eslint-disable-next-line vue/require-prop-types
    "xl",
    // eslint-disable-next-line vue/require-prop-types
    "xxl",
    // eslint-disable-next-line vue/prop-name-casing, vue/require-prop-types
    "offset-sm",
    // eslint-disable-next-line vue/prop-name-casing, vue/require-prop-types
    "offset-md",
    // eslint-disable-next-line vue/prop-name-casing, vue/require-prop-types
    "offset-lg",
    // eslint-disable-next-line vue/prop-name-casing, vue/require-prop-types
    "offset-xl",
    // eslint-disable-next-line vue/prop-name-casing, vue/require-prop-types
    "order-sm",
    // eslint-disable-next-line vue/prop-name-casing, vue/require-prop-types
    "order-md",
    // eslint-disable-next-line vue/prop-name-casing, vue/require-prop-types
    "order-lg",
    // eslint-disable-next-line vue/prop-name-casing, vue/require-prop-types
    "order-xl",
    // eslint-disable-next-line vue/require-prop-types
    "tag",
    // eslint-disable-next-line vue/require-prop-types
    "col",
    // eslint-disable-next-line vue/require-prop-types
    "cols",
    // eslint-disable-next-line vue/require-prop-types
    "offset",
    // eslint-disable-next-line vue/require-prop-types
    "order",
    // eslint-disable-next-line vue/prop-name-casing, vue/require-prop-types
    "align-self",
    // eslint-disable-next-line vue/prop-name-casing, vue/require-prop-types
    "offset-xxs",
    // eslint-disable-next-line vue/prop-name-casing, vue/require-prop-types
    "offset-xxl",
    // eslint-disable-next-line vue/prop-name-casing, vue/require-prop-types
    "order-xxs",
    // eslint-disable-next-line vue/prop-name-casing, vue/require-prop-types
    "order-xxl"
  ],
  data() {
    return {
      options: {},
      classes: {}
    };
  },
  mounted() {
    let cols = {};
    let customClass = {};

    for (var p in this.$props) {
      if (this.$props[p]) {
        if (p === "xxs" || p === "cols") {
          customClass = { ...customClass, ["col-" + this.$props[p]]: true };
        } else if (p === "xs") {
          customClass = { ...customClass, ["col-xs-" + this.$props[p]]: true };
        } else if (p === "xxl") {
          customClass = { ...customClass, ["col-xxl-" + this.$props[p]]: true };
        } else if (p === "offsetXxs") {
          customClass = { ...customClass, ["offset-" + this.$props[p]]: true };
        } else if (p === "offsetXxl") {
          customClass = {
            ...customClass,
            ["offset-xxl-" + this.$props[p]]: true
          };
        } else if (p === "orderXxs") {
          customClass = { ...customClass, ["order-" + this.$props[p]]: true };
        } else if (p === "orderXxl") {
          customClass = {
            ...customClass,
            ["order-xxl-" + this.$props[p]]: true
          };
        } else {
          cols = { ...cols, [p]: this.$props[p] };
        }
      }
    }
    this.options = { ...cols };
    this.classes = { ...customClass };
  }
};
</script>
