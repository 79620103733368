<template>
  <validation-provider :rules="rules" :name="name" v-bind="$attrs" v-slot="{ errors, valid, invalid, validated }">
    <b-form-group :id="id" class="mt-0">
      <slot name="label">
        <label v-if="label" class="field-label-form-group pt-0 mt-0" :class="labelClasses">
          {{ label }}
        </label>
      </slot>
      <div
        :class="[
          { 'input-group': hasIcon },
          { focused: focused },
          { 'input-group-alternative': alternative },
          { 'has-label': label || $slots.label },
          inputGroupClasses,
        ]"
      >
        <slot v-bind="slotData">
          <textarea
            :value="value"
            v-on="listeners"
            v-bind="$attrs"
            :valid="valid"
            class="form-control"
            :rows="rows"
            :class="[
              { 'is-valid': valid && validated && successMessage },
              { 'is-invalid': invalid && validated },
              inputClasses,
            ]"
            :readonly="readonly"
            :disabled="disabled"
            @input="$emit('update-field', $event.target.value)"
            @focus="isInputActive = true"
            @blur="isInputActive = false"
          />
        </slot>
        <slot name="infoBlock"></slot>
      </div>
      <slot name="success">
        <div class="valid-feedback" v-if="valid && validated && successMessage">
          {{ successMessage }}
        </div>
      </slot>
      <b-row align-h="between" class="d-flex">
        <b-col
          ><slot name="error">
            <div v-if="errors[0]" class="invalid-feedback text-xs" style="display: inline-block">
              {{ errors[0] }}
            </div>
          </slot></b-col
        >
        <b-col
          ><span v-if="rules.max" class="text-muted text-xs float-right">
            {{ charCount.value_length }}/{{ charCount.max_length }}
          </span></b-col
        >
      </b-row>
    </b-form-group>
  </validation-provider>
</template>

<script>
export default {
  inheritAttrs: false,
  name: "MTextInput",
  props: {
    id: {
      type: String,
      required: true,
      description: "Id of element",
    },
    group: {
      type: Boolean,
      description: "Whether input is an input group (manual override in special cases)",
    },
    alternative: {
      type: Boolean,
      description: "Whether input is of alternative layout",
    },
    label: {
      type: String,
      description: "Input label (text before input)",
    },
    error: {
      type: String,
      description: "Input error (below input)",
    },
    successMessage: {
      type: String,
      description: "Input success message",
      default: "",
    },
    labelClasses: {
      type: String,
      description: "Input label css classes",
      default: "form-control-label",
    },
    inputClasses: {
      type: String,
      description: "Input css classes",
    },
    inputGroupClasses: {
      type: String,
      description: "Input group css classes",
    },
    value: {
      type: [String, Number],
      description: "Input value",
    },
    rows: {
      type: Number,
      required: false,
      default: 6,
    },
    rules: {
      type: [String, Array, Object],
      description: "Vee validate validation rules",
      default: "",
    },
    name: {
      type: String,
      description: "Input name (used for validation)",
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    readonly: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  emits: ["update-value"],
  data() {
    return {
      focused: false,
    };
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue,
        focus: this.onFocus,
        blur: this.onBlur,
      };
    },
    slotData() {
      return {
        focused: this.focused,
        error: this.error,
        ...this.listeners,
      };
    },
    hasIcon() {
      const { append, prepend } = this.$slots;
      return (
        append !== undefined ||
        prepend !== undefined ||
        this.appendIcon !== undefined ||
        this.prependIcon !== undefined ||
        this.group
      );
    },

    charCount() {
      let max_length = this.rules.max;
      let value_length = 0;
      if (this.value === undefined || this.value === null) {
        value_length = 0;
      } else {
        if (this.rules.max) {
          value_length = this.value.length;
        }
      }

      return { value_length, max_length };
    },
  },
  methods: {
    updateValue(evt) {
      let value = evt.target.value;
      if (this.type === "phone") {
        value = value.replace(/\D+/g, "").replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
      }
      this.$emit("update-value", value);
    },
    onFocus(evt) {
      this.focused = true;
      this.$emit("focus", evt);
    },
    onBlur(evt) {
      this.focused = false;
      this.$emit("blur", evt);
    },
  },
};
</script>
<style></style>
