import axios from "axios"
import { baseDomain } from "@/constants/common"

const apiUrl = `/`

const django_session = axios.create({
  baseURL: `${baseDomain}${apiUrl}`,
})

django_session.interceptors.request.use(async (config) => {
  let token = JSON.parse(localStorage.getItem("GEM_TOKEN"))
  config.headers["Authorization"] = token
  return config
})

export default django_session
