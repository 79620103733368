<template>
  <!-- <div class="">
    <div class="background-overlay">
      <div class="row vh-100 vw-100 align-items-center justify-content-center">
        <div class="wave">
          <div class="rect rect-1"></div>
          <div class="rect rect-2"></div>
          <div class="rect rect-3"></div>
          <div class="rect rect-4"></div>
          <div class="rect rect-5"></div>
        </div>
      </div>
    </div>
  </div> -->
  <div class="container text-center my-5">
    <img
      :src="imgSrc"
      class="img-fluid spin-logo" />
    <div class="text-bold fs-6 mt-4">{{ spinnerMsg }}</div>
  </div>
</template>

<script>
  export default {
    name: "MLoader",
    components: {},

    props: {
      spinnerMsg: {
        type: String,
        default: "Please wait...",
        required: false,
      },
    },

    data() {
      return {
        imgSrc: require("../../../public/gem-regular-gradient.png"),
      }
    },

    computed: {},

    created() {},

    mounted() {},

    methods: {},
  }
</script>

<style scoped>
  .spin-logo {
    top: 50%;
    left: 50%;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 1s linear infinite;
    -moz-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
  }
  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  /* .background-overlay {
    background-color: #c5c5c586;
    margin-left: -80px;
    margin-top: -40px;
    z-index: 10000;
  }
  .wave {
    width: 6em;
    height: 4em;
  }
  .wave .rect {
    height: 100%;
    width: 0.5em;
    display: inline-block;
    animation: wave-stretch-delay 1.2s infinite ease-in-out;
  }

  .wave .rect-1 {
    background-color: #337ab7;
    animation-delay: -1.2s;
  }

  .wave .rect-2 {
    background-color: #33b75d;
    animation-delay: -1.1s;
  }

  .wave .rect-3 {
    background-color: #9db733;
    animation-delay: -1s;
  }
  .wave .rect-4 {
    background-color: #b7335d;
    animation-delay: -0.9s;
  }
  .wave .rect-5 {
    background-color: #d601af;
    animation-delay: -0.8s;
  }
  @keyframes wave-stretch-delay {
    0%,
    40%,
    100% {
      transform: scaleY(0.4);
    }

    20% {
      transform: scaleY(1);
    }
  } */
</style>
